import ExternalLink, {
  ExternalLinkProps,
} from "src/components/core/ExternalLink";
import React, { Fragment } from "react";

import { isFeatureEnabled } from "src/services/featureFlags";

const HealthcareProviderFormLink = (props: ExternalLinkProps) => (
  <Fragment>
    {isFeatureEnabled("documentUploadOptional") ? "" : "completed "}
    <ExternalLink {...props} />
  </Fragment>
);

export default HealthcareProviderFormLink;
