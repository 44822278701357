import { BenefitsApplicationDocument, ClaimDocument } from "./Document";
import employerFlow, { ExemptionsFlowState } from "../flows/employer";
import { get, groupBy, isEmpty } from "lodash";

import EmployerExemptionsApplication from "./EmployerExemptionsApplication";
import { Issue } from "../errors";
import getRelevantIssues from "../utils/getRelevantIssues";

interface Context {
  [key: string]: unknown;
}

/**
 * Unique identifiers for steps in the portal exemptions request. The values
 * map to events in our routing state machine.
 * @enum {string}
 */
export const ExemptionRequestSteps = {
  contactDetails: "CONTACT_DETAILS",
  organization: "ORGANIZATION",
  insuranceDetails: "INSURANCE_DETAILS",
  uploadDocuments: "DOCUMENT_UPLOAD",
} as const;

const fieldHasValue = (fieldPath: string, context: Context) => {
  const value = get(context, fieldPath);

  if (typeof value === "boolean") return true;

  if (typeof value === "number") return true;

  return !isEmpty(value);
};

/**
 * A model that represents a section in a user flow
 * and gives the completion status based on the current
 * state of user data
 */
export default class ExemptionsStep {
  name: string;
  /**
   * Optional method for evaluating whether a step is not applicable,
   * based on the Step's `context`. This is useful if a Step
   * may be skipped for certain types of applications
   */
  notApplicableCond?: (context: Context) => boolean;
  /**
   * What StepGroup number is it associated with (e.g Part 2)
   */
  group: number;
  /**
   * object representing all employer Pages in this step keyed by the page route
   * @see ../flows
   */
  pages: Array<{
    route: string;
    meta: ExemptionsFlowState["meta"];
  }>;

  /**
   * Array of steps that must be completed before this step
   */
  dependsOn?: ExemptionsStep[] = [];
  /**
   * Optional method for evaluating whether a step is complete,
   * based on the Step's `context`. This is useful if a Step
   * has no form fields associated with it.
   */
  completeCond?: (context: Context) => boolean;
  /**
   * Allow/Disallow entry into this step to edit answers to its questions
   */
  editable? = true;
  /**
   * Context used for evaluating a step's status
   */
  context: Context = {};
  /**
   * Array of validation warnings from the API, used for determining
   * the completion status of Steps that include fields. You can exclude
   * this if a Step doesn't include a field, or if it has its own
   * completeCond set.
   */
  warnings?: Issue[];

  constructor(
    attrs: Omit<
      ExemptionsStep,
      | "fields"
      | "isComplete"
      | "isDisabled"
      | "isInProgress"
      | "isNotApplicable"
      | "status"
    >
  ) {
    Object.assign(this, attrs);
  }

  get fields(): string[] {
    return this.pages.flatMap((page) => page.meta?.fields || []);
  }

  get status() {
    // TODO (PFMLPB-20787): remove these hard coded status returns
    if (
      this.name === ExemptionRequestSteps.organization ||
      this.name === ExemptionRequestSteps.insuranceDetails ||
      this.name === ExemptionRequestSteps.uploadDocuments
    ) {
      return "disabled";
    }
    if (this.name === ExemptionRequestSteps.contactDetails)
      return "not_started";
    // end removal
    if (this.isDisabled) {
      return "disabled";
    }

    if (this.isNotApplicable) {
      return "not_applicable";
    }

    if (this.isComplete) {
      return "completed";
    }

    if (this.isInProgress) {
      return "in_progress";
    }

    return "not_started";
  }

  get isComplete() {
    if (this.completeCond) return this.completeCond(this.context);

    const issues = getRelevantIssues(this.warnings || [], this.pages);

    if (process.env.NODE_ENV === "development" && issues.length) {
      // eslint-disable-next-line no-console
      console.log(`${this.name} has warnings`, issues);
    }

    return issues.length === 0;
  }

  get isInProgress() {
    return this.fields.some((field) => fieldHasValue(field, this.context));
  }

  get isNotApplicable() {
    if (this.notApplicableCond) return this.notApplicableCond(this.context);

    return false;
  }

  get isDisabled() {
    if (!this.dependsOn || !this.dependsOn.length) return false;

    return this.dependsOn.some((dependedOnStep) => !dependedOnStep.isComplete);
  }

  /**
   * Create an array of Steps from routing machine configuration
   * @see ../flows/index.js
   * @example createExemptionStepsFromMachine(exemptionFlowConfig, { exemption: {
   * first_name: "Bud" } })
   */
  static createExemptionFromMachine = (
    machineConfigs: typeof employerFlow,
    context: {
      // TODO (PFMLPB-20787): remove conditional from exemptionRequest
      exemptionRequest?:
        | EmployerExemptionsApplication
        | { [key: string]: never };
      certificationDocuments?: BenefitsApplicationDocument[] | ClaimDocument[]; // these documents will need to be replaces with Exemption ones
    } = {
      exemptionRequest: {},
      certificationDocuments: [],
    },
    warnings?: Issue[]
  ) => {
    // TODO (PFMLPB-20787): uncomment below comment
    // const { exemptionRequest } = context;

    const pages = Object.entries(machineConfigs.states).map(([key, state]) =>
      Object.assign({ route: key, meta: state.meta })
    );
    const pagesByStep = groupBy(pages, "meta.step");

    const contactDetails = new ExemptionsStep({
      name: ExemptionRequestSteps.contactDetails,
      // TODO (PFMLPB-20787): change editable to exemptionRequest.isStarted
      editable: true,
      group: 1,
      pages: pagesByStep[ExemptionRequestSteps.contactDetails],
      context,
      warnings,
    });

    const organization = new ExemptionsStep({
      name: ExemptionRequestSteps.organization,
      // TODO (PFMLPB-20787): change editable to exemptionRequest.isStarted
      editable: true,
      group: 1,
      pages: pagesByStep[ExemptionRequestSteps.organization],
      dependsOn: [contactDetails],
      context,
      warnings,
    });

    const insuranceDetails = new ExemptionsStep({
      name: ExemptionRequestSteps.insuranceDetails,
      // TODO (PFMLPB-20787): change editable to exemptionRequest.isStarted
      editable: true,
      group: 1,
      pages: pagesByStep[ExemptionRequestSteps.insuranceDetails],
      dependsOn: [contactDetails, organization],
      context,
      warnings,
    });

    const uploadDocuments = new ExemptionsStep({
      completeCond: (context) => {
        return (
          Array.isArray(context.certificationDocuments) &&
          !!context.certificationDocuments.length
        );
      },
      name: ExemptionRequestSteps.uploadDocuments,
      group: 1,
      pages: pagesByStep[ExemptionRequestSteps.uploadDocuments],
      dependsOn: [contactDetails, organization, insuranceDetails],
      context,
    });

    const steps = [
      contactDetails,
      organization,
      insuranceDetails,
      uploadDocuments,
    ];

    return steps;
  };
}
