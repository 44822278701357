import withUser, { WithUserProps } from "../../../hoc/withUser";

import Fieldset from "../../../components/core/Fieldset";
import FormLabel from "src/components/core/FormLabel";
import PageNotFound from "src/components/PageNotFound";
import QuestionPage from "../../../components/QuestionPage";
import React from "react";
import { Trans } from "react-i18next";
import { isFeatureEnabled } from "src/services/featureFlags";
import { useTranslation } from "../../../locales/i18n";

// TODO (PFMLPB-20787): update this export with all fields being edited in this file
export const fields = [
  "exemptionRequest.contact_name",
  "exemptionRequest.contact_title",
];

export const EmployerExemptionsContactDetails = (props: WithUserProps) => {
  const { user } = props;
  const { t } = useTranslation();
  const { user_leave_administrators } = user;

  // TODO (PFMLPB-19806): removal of feature flag
  const isEnableEmployerExemptionsPortal = isFeatureEnabled(
    "enableEmployerExemptionsPortal"
  );

  // TODO (PFMLPB-20787): update this function to use the Hook update function
  const handleSave = () => {
    props.appLogic.portalFlow.goToNextPage();
    return Promise.resolve();
  };

  if (!isEnableEmployerExemptionsPortal) {
    return <PageNotFound />;
  }
  return (
    <React.Fragment>
      <QuestionPage
        title={t("pages.employersExemptionsContactDetails.title")}
        alertBanner={
          <div>
            <Trans
              i18nKey="shared.employerExemptions.employerEIN"
              values={{
                employer_fein: user_leave_administrators[0].employer_fein,
              }}
            />
            <br />
            <br />
          </div>
        }
        onSave={handleSave}
      >
        <Fieldset>
          <FormLabel
            component="legend"
            hint={
              <Trans i18nKey="pages.employersExemptionsContactDetails.sectionLabelHint" />
            }
          >
            {t("pages.employersExemptionsContactDetails.sectionLabel")}
          </FormLabel>
        </Fieldset>
      </QuestionPage>
    </React.Fragment>
  );
};

// TODO (PFMLPB-20787): update this to wrap with employer exemptions hoc
export default withUser(EmployerExemptionsContactDetails);
